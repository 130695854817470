.contact-us-container {
  padding: 20px;
  font-family: 'Arial, sans-serif';
  line-height: 1.6;
  max-width: 100%;
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.contact-us-title {
  border-bottom: 2px solid blue;
  text-align: center;
}

.animate__animated {
  color: #ff4500;
}

.Head_Office {
  border-bottom: 2px solid blue;
}

.contact-us-content {
  padding: 15px;
  border-radius: 8px;
  color: black;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.contact {
  text-align: left;
  margin-top: 20px;
  flex: 1;
  width: 100%;
}

.contact-us-content p {
  margin: 10px 0;
}

.contact-us-content a {
  color: #0056b3;
  text-decoration: none;
}

.contact-us-content a:hover {
  text-decoration: underline;
}

iframe {
  width: 100%;
  height: 300px;
  border: 0;
}

@media (min-width: 600px) {
  .contact-us-content {
    flex-direction: row;
  }

  iframe {
    height: 450px;
    width: 50%;
  }

  .contact {
    margin-left: 20px;
    width: 50%;
  }
}

@media (min-width: 900px) {
  .contact-us-content {
    max-height: 500px;
  }

  iframe {
    height: 450px;
    width: 60%;
  }

  .contact {
    margin-left: 20px;
    width: 40%;
  }
}
body{
  overflow-x: hidden;
  /* position: relative; */
  width: 100vw;
}

#paragraph{
  line-height: 25px
}

   .about-us-main {
    /* background: url('./Images/greybackground.jpg') no-repeat center center/cover;  */
   background-color: rgb(247, 244, 250);
    height: auto; 
    padding: 20px;
  }
  
  .The-company-container, .Our-mission-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin-bottom: 30px;
    overflow: hidden;
  }
  
  .The-company-background, .Our-mission-background {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    height: auto;
    padding: 20px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  
  .The-company-content, .Our-mission-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .The-company-image-container, .Our-mission-image-container {
    flex: 1;
  }
  
  .The-company-content-container, .Our-mission-content-container {
    flex: 2;
    margin-left: 20px;
  }
  
  .The-company-image, .Our-mission-image {
    width: 100%;
    height: auto;
    margin-top: 8%;
  }
  
  .heading {
    text-align: center;
    color: rgb(115, 83, 145);
    margin-bottom: 20px;
  }
  
  .The-company-container:hover, .Our-mission-container:hover {
    transform: scale(0.9);
  }
  
  @media (max-width: 600px) {
    .The-company-container, .Our-mission-container {
      flex-direction: column;
      padding: 10px;
    }
  
    .The-company-content, .Our-mission-content {
      flex-direction: column;
    }
  
    .The-company-image-container, .Our-mission-image-container {
      margin-bottom: 20px;
    }
  
    .The-company-content-container, .Our-mission-content-container {
      margin-left: 0;
    }
  
    .The-company-background, .Our-mission-background {
      height: auto;
    }
  }
  
  @media (max-width: 400px) {
    .The-company-container, .Our-mission-container {
      padding: 10px;
    }
  
    .The-company-background, .Our-mission-background {
      padding: 10px;
    }
  
    .The-company-content-container, .Our-mission-content-container {
      padding: 10px;
    }
  }





  
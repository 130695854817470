.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 50px;
    padding: 20px;
    box-sizing: border-box;
    height: 100%;
}

.card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
}

.card img {
    width: 100%;
    height: auto;
}

.card-content {
    padding: 15px;
}

.card h3 {
    margin: 10px 0;
    font-size: 1.2em;
}

.demo-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    background-color: #6a5acd;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-size: 18px;
}

.demo-button:hover {
    background-color: #483d8b;
}

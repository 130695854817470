.carrer-section-container {
  /* background-color: #f0f5f5; */
  padding: 20px;
  overflow: hidden;
}

.carrer-lines-div {
  position: relative;
  width: 4px;
  margin-right: 20px; 
  display: block;
  height: 150px;
  margin-left: 7%;
  margin-top: 10px;
}

.carrer-lines-div::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-color: red;
  border-radius: 10px;
}

.carrer-career-content {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-top: 20px;
  /* padding: 0 40px; */
}

.carrer-text-content {
  margin: 0 40px;
  width: 35%;
  margin-left: 40px;
  margin-right: 60px; /* Increased margin to create a 20px additional gap */
}

.carrer-text-content h2 {
  font-size: 26px;
  margin-bottom: 10px;
}

.carrer-text-content p {
  font-size: 16px;
  color: #555;
  margin-top: 30px;
  margin-bottom: 20px;
}

.carrer-image-content {
  width: 35%;
  margin-left: 35px;
}

.carrer-image-content img {
  width: 100%;
  max-width: 400px; /* Set a maximum width for the image */
  border-radius: 8px;
}

.carrer-joinimg {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Media Query for screens 600px and smaller */
@media (max-width: 600px) {
  .carrer-career-content {
    flex-direction: column; /* Stack text and image vertically */
    align-items: center; /* Center align items */
    padding: 0 10px; /* Adjust padding to avoid content touching edges */
  }

  .carrer-text-content {
    width: 100%; /* Full width for text content */
    margin: 0; /* Remove side margins */
    text-align: center; /* Center text alignment */
    padding: 0 10px; /* Add padding for spacing on small screens */
  }

  .carrer-text-content h2 {
    font-size: 22px; /* Adjust font size for better readability on small screens */
    margin-bottom: 10px;
  }

  .carrer-text-content p {
    font-size: 14px; /* Adjust font size for better readability on small screens */
    margin-top: 20px; /* Reduce top margin */
    margin-bottom: 20px; /* Keep bottom margin for spacing */
  }

  .carrer-image-content {
    width: 100%; /* Full width for image content */
    margin: 0; /* Remove side margins */
    text-align: center; /* Center align image */
    padding: 0 10px; /* Add padding for spacing on small screens */
  }

  .carrer-image-content img {
    width: 100%; /* Full width image to fit container */
    max-width: 100%; /* Ensure image does not exceed container width */
    height: auto; /* Maintain aspect ratio */
  }

  .banner-video {
    height: 50vh; /* Adjust height of the banner video for small screens */
  }

  .carrer-lines-div {
    display: none;
  }
}

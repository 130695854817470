
 .home-video {
  width:100%;
  height: 20%;
  border: none;
  outline: none;
  border-radius: 8px;
  object-fit: fill;
  padding: 20px;
  overflow-x: hidden;
  /* border: 5px solid red; */
}
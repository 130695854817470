.perks-container {
  text-align: center;
  padding: 20px;
  margin: 0 auto;
}

.perks-title {
  font-size: 30px;
  margin-bottom: 20px;
  color: rgba(255, 166, 0, 0.948);
}

.perks-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  /* gap: 15px; */
}

.perk-item {
  text-align: center;
  padding: 5%;
}

.background-image {
  height: 55%;
  background-size: cover;
  background-position: center;
}

.perk-icon {
  font-size: 2.5rem;
  color: #6f4b91;
  background-color: #f0f4ff;
  border: 3px solid #6a3997;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
  transition: transform 0.3s;
  animation: fadeIn 0.6s ease-in-out;
}

.perk-title {
  font-size: 1.2rem;
  color: #333;
  margin: 10px 0 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


 @media (max-width: 1200px) {
  .perks-grid {
      grid-template-columns: repeat(4, 1fr); /* Four columns for medium screens */
  }
}

@media (max-width: 992px) {
  .perks-grid {
      grid-template-columns: repeat(3, 1fr); /* Three columns for slightly smaller screens */
  }
}

@media (max-width: 768px) {
  .perks-grid {
      grid-template-columns: repeat(2, 1fr); /* Two columns for small screens */
  }
}

@media (max-width: 480px) {
  .perks-grid {
      grid-template-columns: 1fr; /* One column for very small screens */
  }
}


@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.button {
  width: 60px;
  height: 60px;
  font-size: 16px;
  color: white;
  /* background-color: #684585; */
  /* border: 2px solid #6a3997; */
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  position: relative;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  /* padding: 20%; */
  animation: scaleAnimation 3s infinite;
}


@keyframes borderAnimation {
  0% {
      box-shadow: 0 0 0 0 rgba(186, 123, 251, 0.2); /* Lighter purple */
  }
  70% {
      box-shadow: 0 0 0 20px rgba(186, 123, 251, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(186, 123, 251, 0);
  }
}

.button:before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border-radius: 50%;
  border: 2px solid rgba(186, 123, 251, 0.3); /* Lighter shade */
  animation: borderAnimation 3s infinite;
}



   .main-div {
    max-width: 85%;
    margin: 30px auto;
    padding: 0px 20px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    background-color: #ffffff;
  }
  
  .Enqheader {
    position: relative;
    background-image: url('../Component/Images/java.gif');
    background-size: cover;
    background-position: center;
    height: 80px; 
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
  
  .enq {
    color: orange;
    text-align: center;
    margin: 0;
    padding: 5px;
  }
  
  .Enqheader::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 1;
  }
  
  .Enqheader h1 {
    position: relative;
    color: white; /* Ensure the text color contrasts with the background */
    z-index: 2;
   
  }
  
  .mainflex {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
  }
  
  .mainflex img {
    /* max-width: 100%; */
    width: 100%;
    /* height: auto; */
    border-radius: 8px;
    /* border: 1px solid; */
  }
  
  .form-container {
    /* flex: 1; */
    width: 100%;
    /* margin-left: 20px; */
    /* border: 1px solid; */
  }
  
  .form-container .ant-form-item {
    margin-bottom: 16px;
  }
  
  .form-container .ant-btn-primary {
    width: 100%;
  }
  
  .ant-input {
    width: 100%;
    height: 35px; /* Adjust the height as needed */
  }
  
  /* Responsive styles for screens with a maximum width of 600px */
  @media (max-width: 600px) {
    .main-div {
      padding: 10px;
    }
  
    .mainflex {
      flex-direction: column;
      align-items: center;
    }
  
    .mainflex img {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .form-container {
      width: 100%;
      margin-left: 0;
    }
  
    .Enqheader {
      height: 60px; /* Adjust height as needed */
      
    }
  
    .Enqheader h1 {
      font-size: 22px; /* Adjust font size as needed */
    }
  }
  .btn_color {
    background-color: rgb(141, 106, 174);
  }
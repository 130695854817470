.carrer3-mainop_div {
  background-color: #f8f9fa;
}

.carrer3-opportunities-container {
  width: 80%;
  margin: auto;
  text-align: center;
  overflow: hidden;
}

.carrer3-opportunities-container h1 {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: bold;
  color: #333;
  padding: 20px;
}

.carrer3-opportunity {
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.carrer3-opportunity-alt {
  background-color: #ede6ff;
}

.carrer3-opportunity:hover {
  transform: translateY(-10px);
}

.carrer3-opportunity-content h2 {
  font-size: 25px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carrer3-opportunity-content h2 span {
  font-size: 2rem;
  font-weight: bold;
  color: #6a5acd;
}

.carrer3-opportunity-content p {
  font-size: 1rem;
  color: #333;
}

.icon-grid-container {
  padding: 20px 50px;
  background-color: #f8f9fa; /* Light background for contrast */
  border-radius: 8px; /* Rounded corners for a softer look */
}

.icon-grid-why-choose-us-title {
  text-align: center;
  margin-bottom: 30px; /* Increased margin for better spacing */
  font-size: 30px; /* Slightly larger font size for emphasis */
  font-weight: 700; /* Bolder font weight for prominence */
  color: #333;
  line-height: 1.2; /* Improved line spacing */
}

.icon-grid-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px; /* Increased gap for better spacing */
  justify-items: center;
  animation: rotateBorder 5s linear infinite;
}

.icon-grid-icon-item {
  text-align: center;
  background-color: #fff; /* White background for each item */
  border-radius: 8px; /* Rounded corners for a softer look */
  padding: 20px; /* Padding inside each icon item */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition effects */
}

.icon-grid-icon-item:hover {
  transform: translateY(-10px); /* Slight lift effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow on hover */
}

.icon-grid-icon {
  font-size: 60px; /* Larger icon size for better visibility */
  margin-bottom: 15px; /* Increased margin for better spacing */
  /* color: rgb(76, 165, 255); */
  color:#6a5acd;
  transition: color 0.3s, transform 0.3s;
  animation: pulse 2s infinite;
  transform: scale(1.5);
}

.icon-grid-icon:hover {
  color: #0056b3;
  transform: scale(1.2); /* Slightly larger scale on hover */
}

.icon-grid-icon-item p {
  width: 100%; /* Full width of the container */
  font-size: 16px; /* Larger font size for readability */
  margin: 0; /* Remove default margin */
  color: #555; /* Slightly lighter color for text */
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotateBorder {
  0% {
    border-bottom-color: teal;
  }
  25% {
    border-bottom-color: purple;
  }
  50% {
    border-bottom-color: red;
  }
  75% {
    border-bottom-color: blue;
  }
  100% {
    border-bottom-color: radial-gradient(#b0e633, #53ef7d);
  }
}

/* Media query for screen widths less than 600px */
@media (max-width: 600px) {
  .icon-grid-container {
    padding: 10px 20px;
    background-color: #f1f1f1; /* Lighter background for smaller screens */
  }

  .icon-grid-why-choose-us-title {
    font-size: 24px; /* Adjusted font size for smaller screens */
    margin-bottom: 20px; /* Adjusted margin for smaller screens */
  }

  .icon-grid-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .icon-grid-icon {
    font-size: 50px; /* Adjusted icon size for smaller screens */
  }

  .icon-grid-icon-item p {
    font-size: 14px; /* Adjusted font size for smaller screens */
  }
}
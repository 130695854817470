/* .features-container {
  text-align: center;
  padding: 40px 0;
  color: black;
}

.features-title {
  color: black;
  font-size: 24px;
  margin-bottom: 10px;
}

.features-subtitle {
  color: black;
  font-size: 32px;
  margin-bottom: 40px;
}

.features-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.feature-card {
  width: 25%;
  position: relative;
  padding: 10px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(0px 0px 30px rgb(233, 189, 230));
}

.feature-image {
  width: 100%;
  height: 220px;
  margin-bottom: 20px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.feature-title {
  font-size: 20px;
  color: #6b3c8f;
  margin-bottom: 10px;
}

.feature-description {
  color: #6b3c8f;
  font-size: 16px;
  margin-bottom: 20px;
}

.feature-btn {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #6b3c8f;
  border-color: #6b3c8f;
  width: 40%;
  height: 35px;
}

.feature-btn:hover {
  background-color: #542f8f !important;
  border-color: #542f8f !important;
  color: white !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.feature-overlay {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  transition: left 0.3s ease-in-out;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
}

.feature-card:hover .feature-overlay {
  left: 0;
}


@media (max-width: 600px) {
  .features-subtitle {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .feature-card {
    width: 90%; 
    margin-bottom: 20px;
  }

  .feature-title {
    font-size: 18px;
  }

  .feature-description {
    font-size: 14px;
  }

  .feature-btn {
    width: 60%;
    height: 30px;
  }

  .feature-image {
    height: 180px;
  }
}

@media (min-width: 601px) {
  .feature-card {
    width: 370px;
  }
} */


.features-container {
  text-align: center;
  padding: 40px 0;
  color: black;
  overflow-x: hidden;
}

.features-title {
  color: black;
  font-size: 24px;
  margin-bottom: 10px;
}

.features-subtitle {
  color: black;
  font-size: 32px;
  margin-bottom: 40px;
}

.features-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.feature-card {
  width: 25%;
  position: relative;
  padding: 10px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(0px 0px 30px rgb(233, 189, 230));
}

.feature-image {
  width: 100%;
  height: 220px;
  margin-bottom: 20px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.feature-title {
  font-size: 20px;
  color: #6b3c8f;
  margin-bottom: 10px;
}

.feature-description {
  color: #6b3c8f;
  font-size: 16px;
  margin-bottom: 20px;
}

.feature-btn {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #6b3c8f;
  border-color: #6b3c8f;
  width: 40%;
  height: 35px;
}

.feature-btn:hover {
  background-color: #542f8f !important;
  border-color: #542f8f !important;
  color: white !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.feature-overlay {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  transition: left 0.3s ease-in-out;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
}

.feature-card:hover .feature-overlay {
  left: 0;
}

@media (max-width: 600px) {
  .features-subtitle {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .feature-card {
    width: 90%; 
    margin-bottom: 20px;
  }

  .feature-title {
    font-size: 18px;
  }

  .feature-description {
    font-size: 14px;
  }

  .feature-btn {
    width: 60%;
    height: 30px;
  }

  .feature-image {
    height: 180px;
  }
}

@media (min-width: 601px) {
  .feature-card {
    width: 370px;
  }
}
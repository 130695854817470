* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* background-color: #969599; */
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
  }
  
  .card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(155, 147, 147, 0.1);
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    width: 100%;
    max-width: 330px;
    text-align: center;
    margin: 10px;
     
   
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-img {
    /* height: 90%; */
    object-fit: cover;
  
  }
  
  .card-content {
    padding: 20px;
    color:#805ad5;
  }
  
  .card-content h3 {
    color:#805ad5;
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .card-content p {
    color: black;
    font-size: 0.9em;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .card-button {
    background-color: #805ad5;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    padding: 10px 20px;
    text-transform: uppercase;
    transition: background-color 0.3s;
  }
  
  .card-button:hover {
    background-color: #6b46c1;
  }
  
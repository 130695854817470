@import '~@fortawesome/fontawesome-free/css/all.css';

.footer-container {
  /* background-color:rgb(57,12,100); */
  background: linear-gradient(to right,#9e8eac, #8e71aa,#6a3997);
  color: #fff;
}

.footer {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  flex-wrap: wrap;
}

.footer-section {
  width: 20%;
  font-size: 15px;
}

.footer-section h4 {
  margin-bottom: 15px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.footer-section p {
  line-height: 1.6;
}

.footer-section p i {
  margin-right: 8px;
}

.footer-copyright {
  background:#99979d;
  text-align: center;
  padding: -1px;
  font-size: 14px;
}
.ios {
  font-size: 14px ;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    
  }
  .footer-section {
    width: 100%;
    margin-bottom: 20px;
    
  }
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.image-container {
  position: relative;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

.social-sidebar {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 10;
}

.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 5px 0;
  text-align: center;
  line-height: 50px;
  color: white;
  font-size: 18px;
  text-decoration: none;
  transition: width 0.3s, background 0.3s, transform 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.social-icon span {
  display: none;
  margin-left: 10px;
}

.social-icon:hover {
  width: 150px;
}

.social-icon:hover span {
  display: inline-block;
}

.facebook {
  background: #3b5998;
}

.facebook:hover {
  background: #2d4373;
}

.instagram {
  background: #e4405f;
}

.instagram:hover {
  background: #c32aa3;
}

.youtube {
  background: #ff0000;
}

.youtube:hover {
  background: #cc0000;
}

.linkedin {
  background: #0077b5;
}

.linkedin:hover {
  background: #005582;
}

@media screen and (max-width: 768px) {
  .social-sidebar {
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
  }

  .social-icon {
    margin: 0 5px;
  }
}

.gallery-containers {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(135deg, 
    white, blueviolet, white, #a0c4ff, white, 
    blueviolet, #b9fbc0, blueviolet, white, 
    white, blueviolet, #a0c4ff
  );
  background-size: 400% 400%;
  animation: gradientAnimation 40s ease infinite;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 0%; }
  50% { background-position: 100% 100%; }
  100% { background-position: 0% 0%; }
}

.gallery_background {
  background-image: url('./Images/award.jpg');
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
}

.gallery {
  font-size: 36px;
  font-weight: bold;
  color: white;
}

.button-group .ant-btn {
  background-color: purple;
  color: #ffffff;
}

.button-group .ant-btn:hover {
  color: orange;
}

.button-group .ant-btn:focus {
  background-color: blue;
  color: orange;
}

.gallery-image {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.gallery-item {
  width: 100%;
  height: 100%;
}

/* .gallery-item img,
.gallery-item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.gallery-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 24px;
  color: #fff;
}

.container_gallery {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.gallery_col{
  width: 25%;
}
@media only screen and (max-width: 600px) {
  .gallery_col{
    width: 50%;
  }
  .gallery-containers {
    padding: 10px;
  }

  .gallery_background {
    padding: 10px;
  }

  .gallery {
    font-size: 28px;
  }

  .gallery-image {
    height: 150px;
  }

  .container_gallery {
    height: 150px;
  }

  .button-group .ant-btn {
    font-size: 14px;
    padding: 5px 10px;
  }

  .gallery-icon {
    font-size: 18px;
    bottom: 5px;
    right: 5px;
  }
}

@media only screen and (max-width: 450px) {
  .gallery {
    font-size: 24px;
  }

  .gallery-image {
    height: 120px;
  }

  .container_gallery {
    height: 120px;
  }

  .button-group .ant-btn {
    font-size: 12px;
    padding: 4px 8px;
  }

  .gallery-icon {
    font-size: 16px;
  }
}
.main {
    width: 100%;
    position: relative;
    padding-top: 50px; /* Added padding to ensure content is visible above background image */
}

.portfolio_background_image {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; /* Ensure the image is behind all other content */
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: auto;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    padding-bottom: 50px;
    z-index: 1; /* Ensure the container is above the background image */
    position: relative;
}

.image-div {
    position: relative;
    margin: 10px;
    overflow: hidden;
    height: 300px;
    width: 350px;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.image {
    width: 100%;
    height: 220px;
    display: block;
    transition: transform 0.3s ease;
}

.hover-content {
    height: 36vh;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(31, 31, 31, 0.85);
    color: white;
    padding: 10px 20px;
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
}
/* 
.image-div:hover .hover-content {
    opacity: 1;
} */

.Yash_btn {
    height: 6vh;
    width: 76%;
    text-align: center;
    border: none;
    font-size: 15px;
    margin-left: 15%;
    border-radius: 15px 50px;
    background-color: rgb(188, 150, 224);
    color: black;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
    font-weight: bold;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.Yash_btn:hover {
    background-color: white;
    box-shadow: 0px 10px 10px 0px blueviolet;
    color: black;
    transform: translateY(-7px);
    cursor: pointer;
    border: 1px solid blueviolet;
}
.image-div:hover{
    transform: scale(0.9);
}

@media (max-width: 601px) {
    .portfolio_background_image {
        height: auto;
    }

    .image-div {
        width: 100%;
        height: auto;
    }

    .container {
        padding: 10px;
        height: auto;
        margin-top: 0;
    }

    .Yash_btn {
        height: 5vh;
        width: 80%;
    }
}
/* Existing CSS */

.gallery-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
}

.gallery-section {
    width: 100%;
    height: 100vh;
    background: url('./Images/gif.gif') no-repeat center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

.gallery-content {
    padding: 20px;
    border-radius: 10px;
}

.gallery-content h1 {
    font-size: 4rem;
    margin-bottom: 20px;
    color: #ffc107; /* Change color to your preference */
}

.gallery-content p {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.explore-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    animation: pulse 2s infinite; /* Adding animation */
}

.explore-icon {
    font-size: 48px; /* Size of the icon */
    color: white; /* Icon color */
    transition: color 0.3s, transform 0.3s;
    animation: rotate 5s linear infinite; /* Rotation animation */
}

.explore-icon:hover {
    color: #ffc107; /* Hover color */
    transform: scale(1.2); /* Scale effect on hover */
}

/* Keyframes for the pulse animation */
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }
    50% {
        transform: scale(1.1);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0.8;
    }
}

/* Keyframes for the rotation animation */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.image-gallery {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 20px;
    gap: 20px;
    justify-content: center;
}

.gallery-card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 350px; /* Increased width */
    height: 400px; /* Increased height */
    display: flex;
    flex-direction: column;
}

.gallery-card .ant-card-cover img {
    object-fit: cover;
    height: 200px; /* Adjust height as needed */
}

.gallery-card .ant-card-body {
    padding: 16px; /* Adjust padding if necessary */
}

.gallery-card .ant-card-meta-title {
    font-size: 24px; /* Increased font size for the title */
    margin-bottom: 8px; /* Adjust margin if necessary */
}

.gallery-card .ant-card-meta-description {
    font-size: 17px; /* Increased font size for the description */
    text-align: center; /* Center align text */
    padding-left: 16px; /* Equal spacing from left */
    padding-right: 16px; /* Equal spacing from right */
    color: #333;
}

.gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-width: 100%;
    margin: 25px;
    background-color: #f9f9f9; /* Background for the section */
    color: #333; /* Text color */
    font-family: Arial, sans-serif; /* Font for the section */
    line-height: 1.6;
}

.text-content {
    flex: 1 1 60%;
    padding: 20px;
}

.text-content h1 {
    font-size: 30px;
    margin-bottom: 20px;
}

.text-content p {
    font-size: 18px;
    margin-bottom: 15px;
}

.image-content {
    flex: 1 1 40%;
    padding: 20px;
}

.image-content img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

/* Media query for responsive design at 600px and below */
@media (max-width: 600px) {
    .gallery-section {
        height: auto;
        padding: 20px;
        text-align: center;
    }

    .gallery-content h1 {
        font-size: 2rem; /* Smaller font size */
    }

    .gallery-content p {
        font-size: 1rem; /* Smaller font size */
    }

    .image-gallery {
        flex-wrap: wrap; /* Wrap images to new lines */
        gap: 10px; /* Reduced gap */
    }

    .gallery-card {
        width: 100%; /* Full width */
        height: auto; /* Auto height */
    }

    .gallery-card .ant-card-cover img {
        height: auto; /* Auto height for images */
    }

    .gallery-card .ant-card-meta-title {
        font-size: 1.5rem; /* Smaller font size */
    }

    .gallery-card .ant-card-meta-description {
        font-size: 1rem; /* Smaller font size */
        padding-left: 8px; /* Reduced padding */
        padding-right: 8px; /* Reduced padding */
    }

    .gallery-container {
        flex-direction: column;
        padding: 10px;
    }

    .text-content {
        flex: 1 1 100%;
        padding: 10px;
    }

    .text-content h1 {
        font-size: 24px; /* Adjusted font size */
    }

    .text-content p {
        font-size: 16px; /* Adjusted font size */
    }

    .image-content {
        flex: 1 1 100%;
        padding: 10px;
    }

    .image-content img {
        max-width: 100%;
        height: auto;
    }
}

.carrer1-App {
  text-align: center;
  color: #333;
}

.carrer1-header-container {
  text-align: center;
  margin: 20px auto;
  max-width: 1200px;
  overflow: hidden;
}

.carrer1-main-heading {
  font-size: 30px;
  margin: 10px 0;
  color: #333;
}

.carrer1-main-heading br {
  content: "";
  display: block;
  margin-bottom: 10px; /* Increase the space between lines */
}

.carrer1-sub-heading {
  font-size: 18px;
  margin: 25px 0;
  color: #555;
}

.carrer1-main-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.carrer1-image-container {
  flex: 1;
  margin-right: 40px; /* Space between image and line */
}

.carrer1-image {
  width: 80%;
  height: 350px;
  border-radius: 8px;
  object-fit: cover;
}

.carrer1-line-div {
  position: relative;
  width: 4px;
  margin-right: 20px; /* Space between line and text */
  display: block;
  height: 150px;
}

.carrer1-line-div::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-color: red;
  /* background-color: #6a5acd;; */
  border-radius: 10px;
}

.carrer1-text-container {
  flex: 1;
  text-align: left;
  margin-left: 20px; /* Space on left side of text */
}

.carrer1-text-container h2 {
  margin-bottom: 10px;
  font-size: 27px;
  color: #333;
}

.carrer1-text-container p {
  margin-bottom: 20px;
  font-size: 1em;
  color: #666;
  margin-top: 30px;
}

/* Media query for screen widths less than 600px */
@media (max-width: 600px) {
  .carrer1-header-container {
    padding: 0 10px;
    margin: 10px auto;
    max-width: 100%;
  }

  .carrer1-main-heading {
    font-size: 22px;
  }

  .carrer1-sub-heading {
    font-size: 16px;
  }

  .carrer1-main-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .carrer1-image-container {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  .carrer1-image {
    width: 100%;
    height: auto; /* Adjust height proportionally */
  }

  .carrer1-line-div {
    display: none;
  }

  .carrer1-text-container {
    margin-left: 0;
    text-align: center;
  }

  .carrer1-text-container h2 {
    font-size: 20px;
  }

  .carrer1-text-container p {
    font-size: 0.9em;
    margin-top: 20px;
  }
}

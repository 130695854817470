.navbar {
  display: flex;
  flex-direction: column;
  align-items: center;

  border-bottom: 1px solid #ccc;
  margin-top: -1%;
  height: auto;
  background: linear-gradient(to right, hwb(270 99% 0%), #6a3997);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.hed {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

/* .nav-links a.active {
  background-color: #f0f0f0;
  border-radius: 4px;
  color: #6a3997;
} */


.logo-btn-container {
  display: flex;
  align-items: center;
  width: 90%;
  margin-left: 5%;
  justify-content: space-between;
}

.logo img {
  max-width: 150px;
  height: auto;
}

.nav-links {
  display: flex;
  list-style: none;
  width: 100%;
  justify-content: space-around;
  margin-top: -1%;
}

.nav-links li {
  position: relative;
  font-size: smaller;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-weight: 00;
  padding: 10px;
  display: flex;
  align-items: center;
  font-family: sans-serif;
  font-size: 14px;
  
}

.nav-links a:hover {
  background-color: #f0f0f0;
  border-radius: 4px;
  color: #6a3997;
}

.menu-toggle {
  display: none;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .hed {
    flex-direction: row;
    justify-content: space-between;
  }

  .logo-btn-container {
    width: 100%;
    justify-content: space-between;
  }

  .menu-toggle {
    display: block;
    align-self: flex-end;
    color: white;
    margin-bottom: 5px;
    margin-top: -30px;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links a {
    width: 100%;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 600px) {
  .hed {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .logo-btn-container {
    width: 80%;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-left: 5%;
  } 

  .logo img {
    max-width: 100px;
  } 

  .btn {
    font-size: 8px;
    padding: 5px 10px;
    width: 50px;
    padding-right: 20px;
    margin-right: 60%;
  } 

  .nav-links a {
    font-size: 14px;
    padding: 10px 20px;
    width: 150px;
    margin: auto;
    /* border: 2px solid green; */
  }
}

@keyframes borderAnimation {
  0% {
    box-shadow: 0 0 0 0 rgba(106, 57, 151, 0.7);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(106, 57, 151, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(106, 57, 151, 0);
  }
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.btn {
  width: 150px;
  height: 20px;
  font-size: 12px;
  color: white;
  background-color: #684585;
  border: 2px solid #6a3997;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  animation: scaleAnimation 3s infinite;
  /* margin-left: 1000px; */
  position: relative;
}

.btn:before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border-radius: 25px;
  border: 2px solid rgba(106, 57, 151, 0.5);
  animation: borderAnimation 3s infinite;
}
body {
  overflow-x: hidden; /* Avoid horizontal scroll bar */
}

.career-page-title {
  font-size: 30px;
  text-align: center;
  padding: 20px;
  margin-top: 20px;
  
}

.career-page-subtitle {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
 color: #555;
 font-weight: normal;
}

.company_img_carrer {
  max-height: 300px;
}

.highlight-item {
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.highlight-item h3 {
  font-size: 1.25rem;
  margin-top: 10px;
  /* color: #1890ff; */
  color: #6a5acd;
}

.highlight-item p {
  font-size: 1rem;
  color: #666;
}

.highlight-item img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.client-slider {
    /* border: 1px solid; */
    width: 90%;
    margin-left: 5%;
    /* height: 20vh; */
}

.client-slide img {
    margin-left: 5%;
    width: 60%;
    height: auto;
    border-radius: 60px;
}
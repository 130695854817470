.clients-container {
  text-align: center;
  padding: 20px;
  margin-top: 20px;
}

.clients-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #003a8c; 
}

.clients-grid {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* border: 1px solid; */
}


.client-card {
  /* border: 1px solid; */
  width: 100%;
  height:120px ;
  max-width: 160px;
    padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.client-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.client-image {
  max-width: 100%;
  /* height: auto; */
  height: 90px;
  /* object-fit: cover; */
  /* display: block; */
  margin: 0 auto;
}


.client_map_col{
  width: 17%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  margin: 1%;
}
.client_map_col:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 768px) {
  .client_map_col{
    width: 30%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    margin: 1%;
  }
}
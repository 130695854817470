
  
  .improve-skills-container {
    position: relative;
    background: url('../Component/Images/giphy.gif') no-repeat center center;
    background-size: cover;
    padding: 40px;
    color: #fff;
    text-align: center;
    overflow: hidden; /* Ensure content doesn't overflow */
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(50, 50, 122, 0.6); /* Blue overlay with 60% opacity */
    z-index: 1;
  }
  
  .improve-skills-header {
    position: relative;
    z-index: 2;
    margin-bottom: 40px;
  }
  
  .sub-title {
    font-size: 25px;
    color: #fff;
  }
  
  .main-title {
    font-size: 70px;
    font-weight: bold;
    color: #f39c12; /* Example color for the main title */
    margin: 20px 0;
  }
  
  .description {
    font-size: 20px;
    line-height: 1.5;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  
  .button-row {
    margin-top: 40px;
    position: relative;
    z-index: 2;
  }
  
  .button-col {
    display: flex;
    justify-content: center;
  }
  
  .info-button {
    width: 100%;
    max-width: 200px;
    background-color: #f39c12;
    border-color: #f39c12;
    color: #fff;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  
  .info-button:hover {
    background-color: #e67e22;
    border-color: #e67e22;
    color: #fff;
  }
  